.cart-products-list-item {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.brand-color-purple {
  color: #6301f2;
}
.quantity_row{
  display: flex;
  justify-content: space-between;
}

.cart_card .ant-card-body{
  max-height: 60vh;
  overflow-y: scroll;
}
.pos_tabs  {
 width: 100%;
}
.pos_tabs .ant-tabs-tab {
    margin: 0 0 0 32px !important;

}
@media screen and (max-width: 1400px) {
  .quantity_row {
    flex-direction: column;
  }
  .cart_card .ant-card-body{
    max-height: 55vh;
  }
}
@media screen and (max-width: 1300px) {
  .cart_card .ant-card-body{
    max-height: 50vh;
  }
}