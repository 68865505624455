@import "~antd/dist/antd.less";

// Customize Variables
@primary-color: #6301f2; // primary color for all components
@secondary-color: #f73f1f;
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #1f1d1e; // major text color
@text-color-secondary: #5a5659; // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
// @border-radius-base: 5px;
@card-radius: 0px;

.site-page-header {
  margin-bottom: 20px;
}

// for english

@font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
      url('../../fonts/Comfortaa/Comfortaa-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
      url('../../fonts/Comfortaa/Comfortaa-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// for arabic

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff2') format('woff2'),
      url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff2') format('woff2'),
      url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app_ltr,
.app_ltr html,
.app_ltr div, 
.app_ltr span, 
.app_ltr p, 
.app_ltr h1, 
.app_ltr h2, 
.app_ltr h3, 
.app_ltr h4, 
.app_ltr h5, 
.app_ltr h6, 
.app_ltr label,
.app_ltr input,
.app_ltr select,
.app_ltr textarea,
.app_ltr button, 
.app_ltr optgroup{
  font-family: 'Comfortaa' !important;
}
.app_rtl,
.app_rtl html,
.app_rtl div, 
.app_rtl span, 
.app_rtl p, 
.app_rtl h1, 
.app_rtl h2, 
.app_rtl h3, 
.app_rtl h4, 
.app_rtl h5, 
.app_rtl h6, 
.app_rtl label,
.app_rtl input,
.app_rtl select,
.app_rtl textarea,
.app_rtl button, 
.app_rtl optgroup{
  font-family: 'Noto Kufi Arabic' !important;
}
