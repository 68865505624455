.site-layout-background {
  background: #fff;
}

.ant-tooltip a {
  color: #fff;
}

.app_ltr,
.app_ltr html,
.app_ltr div, 
.app_ltr span, 
.app_ltr p, 
.app_ltr h1, 
.app_ltr h2, 
.app_ltr h3, 
.app_ltr h4, 
.app_ltr h5, 
.app_ltr h6, 
.app_ltr label,
.app_ltr input,
.app_ltr select,
.app_ltr textarea,
.app_ltr button, 
.app_ltr optgroup{
  font-family: 'Comfortaa' !important;
}
.app_rtl,
.app_rtl html,
.app_rtl div, 
.app_rtl span, 
.app_rtl p, 
.app_rtl h1, 
.app_rtl h2, 
.app_rtl h3, 
.app_rtl h4, 
.app_rtl h5, 
.app_rtl h6, 
.app_rtl label,
.app_rtl input,
.app_rtl select,
.app_rtl textarea,
.app_rtl button, 
.app_rtl optgroup{
  font-family: 'Noto Kufi Arabic' !important;
}

